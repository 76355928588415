<template>
  <div style="padding-bottom: 4.5%">
    <div>
      <h1 class="mr-sm-4 header-tablepage">BUSINESS INFORMATION</h1>
    </div>
    <div class="title-panel mt-3">Branch Info</div>
    <b-container class="no-gutters bg-white" v-if="isLoading">
      <div class="pt-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Branch Name"
              placeholder="Branch Name"
              v-model="field.business_info[0].value"
              type="text"
              name="branch_name_en"
              isRequired
            />
          </b-col>

          <b-col sm="12" xs="12" lg="12">
            <InputTextArea
              textFloat="Address"
              placeholder="Please add adress : house no., room, building"
              type="text"
              name="address"
              v-model="field.business_info[1].value"
            />
          </b-col>
          <template v-for="fieldBi of field.business_info">
            <b-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              :key="fieldBi.id"
              v-if="checkField(fieldBi.id)"
            >
              <InputText
                :textFloat="fieldBi.name"
                :placeholder="fieldBi.name"
                type="text"
                :name="fieldBi.name"
                v-model="fieldBi.value"
              />
            </b-col>
          </template>
          <b-col
            v-for="list of field.social_list"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            :key="'social-' + list.id"
          >
            <InputText
              :textFloat="list.name"
              :placeholder="list.name"
              type="text"
              name="telephone"
              v-model="list.url"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <FooterAction routePath="/setting" @submit="saveBusinessConfig()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import InputText from "@/components/inputs/InputText";

const FieldID = [1, 2];
export default {
  components: {
    InputText,
    ModalLoading,
    InputTextArea,
  },

  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
        name: "",
        name_en: "",
        short_name: "",
        active: 1,
        allow_time_change: 1,
        type: [],
        plant_id: "",
        plant_ref_number: "",
        branch_type_id: null,
        branch_group_template_id: null,
        telephone: "",
        open_day: "",
        open_time: null,
        close_time: null,
        address: "",
        subdistrict: "",
        district: "",
        province: "",
        zip_code: "",
        add_sales_channel: [],
        add_sales_id: [],
        sales_id: [],
      },
      field: [],
      selected: null,
      styleDatetime: {
        width: "150%",
        border: "none",
      },
      type: ["Service", "Product"],
    };
  },
  mounted() {
    this.getFieldBussiness();
  },
  methods: {
    checkField(id) {
      return !FieldID.includes(id);
    },
    openClock() {
      this.$refs.TimeInput[0].isOpen = true;
    },
    async getFieldBussiness() {
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/setting/businessinfo`
      );
      this.isLoading = true;
      this.field = response.data.detail;
    },
    async saveBusinessConfig() {
      this.$refs.modalLoading.show();
      const result = await this.$Axios.post(
        `${process.env.VUE_APP_API}/setting/businessinfo`,
        this.field
      );

      if (result.data.result == 1) {
        this.$swal2
          .fire(result.data.message, "", "success")
          .then((val) => val && this.$router.push("/setting"));
      } else {
        this.$swal2.fire("เกิดข้อผิดพลาด", result.data.message, "error");
      }
      this.$refs.modalLoading.hide();
    },
  },
};
</script>
<style>
.input-container {
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 0.4rem;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.label-text {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
</style>
